import React, {
  CSSProperties,
  ReactNode,
  TouchEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import classnames from "classnames";
import MessageMenuOptionsList from "chat/components/MessageMenu/MessageMenuOptionsList/MessageMenuOptionsList";
import { MessageReactionsList } from "chat/components/MessageMenu/MessageReactionsList/MessageReactionsList";
import { ConversationMessagesAnimatedMenuContext } from "chat/context/ConversatioMessagesAnimatedMenuContext";
import { useDetectTouchMove } from "chat/hooks/useDetectTouchMove";
import useLongPressTouch from "chat/hooks/useLongPressTouch";
import { useExclusiveClickHandler } from "chat/imports/hooks";
import { StoredMessage } from "chat/state";
import {
  AnimatedMessagesAnchor,
  MessageAnimatedOffsetData,
  getMessageAnimatedMenuOffset,
} from "chat/utils/getMessageAnimatedMenuOffset";
import styles from "./MessageAnimatedMenu.scss";

const initialOffsetState: MessageAnimatedOffsetData = {
  menuOffset: 0,
  reactionsOffset: 0,
  messagesOffset: 0,
  reactionsAnchor: AnimatedMessagesAnchor.MESSAGE,
  menuAnchor: AnimatedMessagesAnchor.MESSAGE,
};

export interface MessageAnimatedMenuProps {
  accountId?: string;
  children: ReactNode;
  containerClassName: string;
  conversationId: string;
  isMyMessage: boolean;
  message: StoredMessage;
}

export const MessageAnimatedMenu = ({
  children,
  message,
  conversationId,
  isMyMessage,
  containerClassName,
  accountId = "",
}: MessageAnimatedMenuProps) => {
  const isScrollingInProgress = useDetectTouchMove();
  const [startClose, setStartClose] = useState(false);
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState(initialOffsetState);
  const [footerTop, setFooterTop] = useState(0);
  const [messagePosition, setMessagePosition] = useState({
    top: 0,
    left: 0,
    right: 0,
    width: 0,
    height: 0,
  });
  const backdropContainerDimension = useContext(
    ConversationMessagesAnimatedMenuContext
  );
  const intercept = useExclusiveClickHandler();
  const reactionsRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const backdropRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);

  const reactionsOffsetStyle = {
    "--animationOffset": `${offset.reactionsOffset}px`,
  } as CSSProperties;
  const menuOffsetStyle = {
    "--animationOffset": `${offset.menuOffset}px`,
  } as CSSProperties;
  const messagesOffsetStyle = {
    "--animationOffset": `${offset.messagesOffset}px`,
  } as CSSProperties;

  const handleCloseMenu = () => {
    setStartClose(true);

    setTimeout(() => {
      setStartClose(false);
      setOpen(false);
    }, 250);
  };

  useEffect(() => {
    if (open && reactionsRef?.current && menuRef?.current) {
      const { height: reactionsHeight } =
        reactionsRef.current.getBoundingClientRect();
      const { height: menuHeight } = menuRef.current.getBoundingClientRect();
      const offset = getMessageAnimatedMenuOffset({
        messageTopCoordinate: messagePosition.top,
        messageHeight: messagePosition.height,
        messageBottomCoordinate: messagePosition.height + messagePosition.top,
        reactionsHeight,
        headerHeight: backdropContainerDimension.headerHeight,
        footerTop,
        menuHeight,
        conversationListContainerHeight: backdropContainerDimension.height,
      });

      setOffset(offset);
    }
  }, [
    open,
    menuRef,
    reactionsRef,
    backdropContainerDimension.height,
    backdropContainerDimension.headerHeight,
    footerTop,
    messagePosition.top,
    messagePosition.height,
  ]);

  const clickOutsideHandler = useCallback((event) => {
    if (
      backdropRef.current &&
      !backdropRef.current.contains(event.target as Node)
    ) {
      handleCloseMenu();
    }
  }, []);

  const handleOpenMenu = () => {
    setOpen(true);
  };

  const handleLongTouch = (event: TouchEvent) => {
    event.preventDefault();
    if (isScrollingInProgress) {
      return;
    }

    if (anchorRef.current) {
      const rect = anchorRef.current.getBoundingClientRect();
      const conversationInputBarHtmlElement =
        document.querySelector<HTMLElement>(
          "[data-testid='conversation-input-bar']"
        );
      const conversationInputRect =
        conversationInputBarHtmlElement?.getBoundingClientRect();
      setMessagePosition({
        top: rect.top,
        left: rect.left,
        right: rect.right,
        width: rect.width,
        height: rect.height,
      });
      setFooterTop(conversationInputRect?.top ?? 0);

      handleOpenMenu();
    }
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    const rect = event.currentTarget.getBoundingClientRect();
    const conversationInputBarHtmlElement = document.querySelector<HTMLElement>(
      "[data-testid='conversation-input-bar']"
    );
    const conversationInputRect =
      conversationInputBarHtmlElement?.getBoundingClientRect();

    setMessagePosition({
      top: rect.top,
      left: rect.left,
      right: rect.right,
      width: rect.width,
      height: rect.height,
    });

    setFooterTop(conversationInputRect?.top ?? 0);

    handleOpenMenu();
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", clickOutsideHandler);
      document.addEventListener("touchstart", clickOutsideHandler);
    }

    return () => {
      document.removeEventListener("mousedown", clickOutsideHandler);
      document.removeEventListener("touchstart", clickOutsideHandler);
    };
  }, [open, clickOutsideHandler]);

  const longPressProps = useLongPressTouch({
    onLongPress: handleLongTouch,
    options: { delay: 200 },
  });

  const menuAndReactionsXPosition = isMyMessage
    ? {
        right: backdropContainerDimension.right - messagePosition.right,
      }
    : {
        left: messagePosition.left - backdropContainerDimension.left,
      };

  const reactionsYPosition = {
    top:
      offset.reactionsAnchor === AnimatedMessagesAnchor.MESSAGE
        ? messagePosition.top - 48 + 20 // Reactions Height, starting point + 20
        : backdropContainerDimension.headerHeight - 48, // header height
  };

  const menuYPosition = {
    top:
      offset.menuAnchor === AnimatedMessagesAnchor.MESSAGE
        ? messagePosition.top + messagePosition.height - 20
        : footerTop,
  };

  return (
    <>
      <div
        ref={anchorRef}
        onContextMenu={handleContextMenu}
        {...longPressProps}
        className={classnames(styles.targetMessage, {
          [styles.targetInvisible]: open,
          [styles.targetVisible]: !open,
        })}
      >
        {children}
      </div>

      {open &&
        createPortal(
          <div
            style={{
              top: backdropContainerDimension.top,
              left: backdropContainerDimension.left,
              width: backdropContainerDimension.width,
              height: backdropContainerDimension.height,
            }}
            onClick={handleCloseMenu}
            className={classnames(styles.backdrop, {
              [styles.fadeIn]: open && !startClose,
              [styles.fadeOut]: startClose && open,
            })}
            ref={backdropRef}
          >
            <div
              ref={reactionsRef}
              className={classnames(styles.reactionsWrapper, {
                [styles.reactionBounceIn]: !startClose && open,
                [styles.reactionBounceOut]: startClose && open,
                [styles.anchorHeader]:
                  offset.reactionsAnchor === AnimatedMessagesAnchor.HEADER,
              })}
              style={{
                ...reactionsYPosition,
                ...menuAndReactionsXPosition,
                ...reactionsOffsetStyle,
              }}
            >
              <MessageReactionsList
                closeMenu={handleCloseMenu}
                message={message.id}
                messageSenderId={message.from}
                conversationId={conversationId}
                accountId={accountId}
              />
            </div>

            <div
              className={classnames(styles.messageContent, {
                [styles.fadeIn]:
                  offset.messagesOffset !== 0 && open && !startClose,
                [styles.fadeOut]:
                  offset.messagesOffset !== 0 && startClose && open,
              })}
              style={{
                top: messagePosition.top,
                left: messagePosition.left - backdropContainerDimension.left,
                width: messagePosition.width,
                height: messagePosition.height,
                ...messagesOffsetStyle,
              }}
            >
              <div onClick={intercept} className={containerClassName}>
                {children}
              </div>
            </div>

            <div
              ref={menuRef}
              className={classnames(styles.menuWrapper, {
                [styles.menuBounceIn]: open && !startClose,
                [styles.menuBounceOut]: startClose && open,
                [styles.anchorFooter]:
                  offset.menuAnchor === AnimatedMessagesAnchor.FOOTER,
              })}
              style={{
                ...menuYPosition,
                ...menuAndReactionsXPosition,
                ...menuOffsetStyle,
              }}
            >
              <MessageMenuOptionsList
                message={message}
                isMyMessage={isMyMessage}
                closeMenu={handleCloseMenu}
              />
            </div>
          </div>,
          document.body
        )}
    </>
  );
};
