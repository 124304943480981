import { IntlFormatters } from "react-intl";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { emitMessageSentEvent } from "chat/analytics/emitMessageSentEvent";
import emitMessageTranslateEvent, {
  TranslateItemIds,
  getScreenNameByConversationId,
} from "chat/analytics/emitMessageTranslateEvent";
import { emitReactionSentEvent } from "chat/analytics/emitReactionSentEvent";
import {
  GetConversationParams,
  GetConversationResponse,
  GetConversationsParams,
  GetConversationsResponse,
  GetMessageTranslationResponse,
  ReadConversationParams,
  ReadConversationResponse,
  SendMessageResponse,
  deleteChatRequest,
  detectMessageLang,
  getChatEventsRequest,
  getConversation,
  getConversations,
  getMessageTranslation as getMessageTranslationApi,
  getMessagesById,
  getReactionsByChatRequest,
  getSelfReactions,
  getUnreadStatusRequest,
  leaveGroupChatRequest,
  readConversationMessages,
  readReactionsToSelfRequest,
  sendMessage as sendMessageApi,
  sendMessageReactionRequest,
} from "chat/api/chat";
import { deleteMessage } from "chat/api/deleteMessage";
import { editMessage } from "chat/api/editMessage";
import { fetchMosV2ForChat } from "chat/api/mos";
import { setMuted } from "chat/api/muteChat";
import {
  getConversationsByIds,
  getPinnedChatsIds,
  setPinned,
} from "chat/api/pinChat";
import { uploadVideo as uploadVideoApi } from "chat/api/uploadVideo";
import {
  ApiMessageType,
  MessageStatusResult,
  ParamConversationState,
  ReactionStatusResult,
  TranslateItemType,
} from "chat/enums";
import { uploadImage as uploadImageApi } from "chat/imports/api";
import { FollowSource, ToastType } from "chat/imports/constants";
import { FetcherMetaV2, RootState, addToast, follow } from "chat/imports/state";
import { Nullable } from "chat/imports/types";
import {
  isApiError,
  prepareImageForUpload,
  sharedMessages,
} from "chat/imports/utils";
import { deleteConversation } from "chat/messageRequest/api/messageRequestApi";
import { convertKeysToSnakeCase } from "chat/messageRequest/exports/common";
import { fetchConversationMessageRequest } from "chat/messageRequest/state/asyncAction";
import { DeleteMessageRequestsResponse } from "chat/messageRequest/types";
import { getIsPinChatEnabled } from "chat/soc/chatSoc";
import { StoredMessage } from "chat/state/reducer";
import chatSelectors from "chat/state/selectors";
import {
  ChatEvent,
  ChatEventsRequest,
  ChatEventsResponse,
  ChatMessageAnalyticsParams,
  ChatMessageFailReason,
  ChatReactionAnalyticsParams,
  DeleteChatPayload,
  DeleteChatRequest,
  DeleteChatResponse,
  FetchMosV2ChatLineupResponse,
  GetMessageSelfReactionRequest,
  GetMessageTranslationParams,
  GetMessagesByIdRequest,
  GetMessagesByIdResponse,
  GetPinedChatsRequestResponse,
  GetReactionsByChatRequest,
  GetReactionsByChatResponse,
  GetSelfMessagesReactionsResponse,
  LeaveGroupChatRequest,
  LeaveGroupChatResponse,
  ReadReactionsResponse,
  SendMessageReactionRequest,
  SendMessageReactionResponse,
  SetPinedChatResponse,
  UnreadMessagesRequest,
  UnreadMessagesResponse,
  UpdateMessageReactionRequest,
} from "chat/types";
import { getMissingPinnedConversationsIds } from "chat/utils/getMissingPinnedConversationsIds";
import { getVideoInfo } from "chat/utils/getVideoInfo";
import isGroupChatId from "chat/utils/isGroupChatId";
import { MosError, MosLineupRequestRejectError } from "src/features/mos/types";

export const setCurrentConversationId = createAction<Nullable<string>>(
  "lwc/chat/setCurrentConversationId"
);

export const updateChatMessageBlur = createAction<{
  conversationId: string;
  isBlurred: boolean;
  messageId: number;
}>("lwc/chat/updateMessageBlur");

export const deleteMessages = createAction<{
  messages: ChatEvent[];
}>("lwc/chat/deleteMessages");

export const muteChatFromChatEvents = createAction<{
  conversationId: string;
  mute: boolean;
  timestamp: number;
}>("lwc/chat/muteChatFromChatEvents");

export const resetDeletedChatUserId = createAction(
  "lwc/chat/resetDeletedChatUserId"
);

export const deleteChatFromChatEvent = createAction<{ conversationId: string }>(
  "lwc/chat/deleteChatFromChatEvent"
);

export const uploadImage = createAsyncThunk<
  {
    height: number;
    mediaId: string;
    thumbnailUrl: string;
    url: string;
    width: number;
  },
  {
    conversationId: string;
    imageUrl: string;
    messageId: string;
    retryId?: string;
  },
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/uploadImage",
  async ({ imageUrl, retryId, conversationId }, api) => {
    if (retryId) {
      const {
        download_url = "",
        thumbnail_url = "",
        media_id = "",
        width = 0,
        height = 0,
        isUploading,
      } = chatSelectors
        .getMessagesByConversationId(api.getState(), conversationId)
        .find((message) => message.id.requestId === retryId)?.media?.[0] || {};

      if (!isUploading) {
        return {
          url: download_url,
          thumbnailUrl: thumbnail_url,
          mediaId: media_id,
          width,
          height,
        };
      }
    }

    try {
      const { width, height, ...imagePreparedForUpload } =
        await prepareImageForUpload(imageUrl);
      const { url, thumbnailUrl, mediaId } = await uploadImageApi(
        imagePreparedForUpload
      );

      return { url, thumbnailUrl, width, height, mediaId };
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  }
);

export const uploadVideo = createAsyncThunk<
  { mediaId: string; thumbnailUrl: string; url: string },
  {
    conversationId: string;
    file: File;
    messageId: string;
    retryId?: string;
  },
  { rejectValue: string; state: RootState }
>("lwc/chat/uploadVideo", async ({ file }, api) => {
  try {
    return await uploadVideoApi(file);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const fetchConversations = createAsyncThunk<
  GetConversationsResponse,
  { includePinned?: boolean; isRefreshing?: boolean } & FetcherMetaV2 &
    GetConversationsParams,
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/fetchConversations",
  async (
    { resetOnError, isRefreshing, includePinned, ...requestParams },
    api
  ) => {
    let pinned = {} as GetPinedChatsRequestResponse;
    const isPinned = getIsPinChatEnabled(api.getState());

    try {
      const requests = await getConversations({
        ...requestParams,
        state: ParamConversationState.CHAT_REQUEST,
      });

      const conversation = await getConversations(requestParams);

      if (isPinned) {
        pinned = await getPinnedChatsIds();
      }

      const convertedObject = convertKeysToSnakeCase(
        requests
      ) as GetConversationsResponse;
      const mergedConversations = {
        ...conversation,
        ...(isPinned && { pinned }),
        conversations: [
          ...(conversation.conversations || []),
          ...(convertedObject.conversations || []),
        ],
      };

      if (
        mergedConversations.has_more_conversations &&
        pinned.pinnedChatInfos?.length
      ) {
        const missingPinedConversations = getMissingPinnedConversationsIds(
          mergedConversations.conversations,
          pinned.pinnedChatInfos
        );

        if (missingPinedConversations.length) {
          const pinnedConversations = await getConversationsByIds({
            conversation_ids: missingPinedConversations,
            direction: requestParams.direction,
          });

          const convertedPinned = convertKeysToSnakeCase(
            pinnedConversations
          ) as GetConversationsResponse;

          mergedConversations.conversations = [
            ...mergedConversations.conversations,
            ...(convertedPinned.conversations || []),
          ];
        }
      }

      return mergedConversations;
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  }
);

export const fetchConversationsRefresh = createAsyncThunk<
  GetConversationsResponse,
  { isRefreshing?: boolean } & FetcherMetaV2 & GetConversationsParams,
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/fetchConversations",
  async ({ resetOnError, isRefreshing, ...requestParams }, api) => {
    try {
      return await getConversations(requestParams);
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  }
);

export const fetchConversation = createAsyncThunk<
  GetConversationResponse,
  { conversationId: string } & GetConversationParams,
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/fetchConversation",
  async ({ conversationId, ...requestParams }, api) => {
    try {
      const conversation = await getConversation(conversationId, requestParams);
      api.dispatch(
        fetchConversationMessageRequest({ conversationId, ...conversation })
      );

      return conversation;
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  }
);

export const readMessages = createAsyncThunk<
  ReadConversationResponse,
  ReadConversationParams,
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/readMessages",
  async (params, api) => {
    try {
      return await readConversationMessages(params);
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  },
  {
    condition: (args, api) => {
      const state = api.getState();
      const conversation = chatSelectors.getConversation(
        state,
        args.conversation_id
      );

      return !conversation?.isLoading;
    },
  }
);

export const fetchSelfMessagesReactions = createAsyncThunk<
  GetSelfMessagesReactionsResponse,
  GetMessageSelfReactionRequest,
  { rejectValue: string; state: RootState }
>(
  "lwc/messageRequest/fetchSelfMessagesReactions",
  async ({ limitPerChat, startTimestamp }, api) => {
    try {
      return await getSelfReactions({
        limitPerChat,
        startTimestamp,
      });
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  }
);

export const fetchUnreadMessagesRequest = createAsyncThunk<
  UnreadMessagesResponse,
  UnreadMessagesRequest,
  { rejectValue: string; state: RootState }
>("lwc/messageRequest/fetchUnreadMessagesRequest", async (payload, api) => {
  try {
    return await getUnreadStatusRequest(payload);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export type BaseMessagePayload = {
  conversationId: string;
  formatMessage: IntlFormatters["formatMessage"];
  from: string;
  retryId?: string;
};

export type TextMessagePayload = {
  body: string;
  imageUrl?: undefined;
  mediaData?: undefined;
  mediaFile?: undefined;
};

export type ImageMessagePayload = {
  body?: undefined;
  imageUrl: string;
  mediaData?: undefined;
  mediaFile?: undefined;
};

export type VideoMediaData = {
  duration: number;
  height: number;
  loadingThumbnail?: string;
  width: number;
};

export type VideoMessagePayload = {
  body?: undefined;
  imageUrl?: undefined;
  mediaData: VideoMediaData;
  mediaFile: File;
};

export const sendTextMessage = createAsyncThunk<
  SendMessageResponse,
  {
    analyticsParams: ChatMessageAnalyticsParams;
  } & BaseMessagePayload &
    TextMessagePayload,
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/sendTextMessage",
  async ({ conversationId, body, retryId, analyticsParams }, api) => {
    const isGroupChat = isGroupChatId(conversationId);

    try {
      if (!isGroupChat && !retryId) {
        api.dispatch(follow(conversationId, FollowSource.CHAT));
      }

      const response = await sendMessageApi({
        to: [conversationId],
        options: {
          store_message_for_recipient: true,
          store_message_for_sender: true,
        },
        messages: [
          {
            message_type: ApiMessageType.TEXT_MESSAGE,
            body,
          },
        ],
      });

      emitMessageSentEvent(analyticsParams, {
        result: MessageStatusResult.SUCCESS,
      });

      return response;
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;
      emitMessageSentEvent(analyticsParams, {
        result: MessageStatusResult.FAILURE,
        reason: ChatMessageFailReason.API_ERROR,
      });

      return api.rejectWithValue(error);
    }
  }
);

export const sendImageMessage = createAsyncThunk<
  SendMessageResponse,
  {
    analyticsParams: ChatMessageAnalyticsParams;
  } & BaseMessagePayload &
    ImageMessagePayload,
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/sendImageMessage",
  async ({ conversationId, imageUrl, retryId, analyticsParams }, api) => {
    const isGroupChat = isGroupChatId(conversationId);

    try {
      if (!isGroupChat) {
        await api.dispatch(follow(conversationId, FollowSource.CHAT));
      }

      const { width, height, thumbnailUrl, url } = await api
        .dispatch(
          uploadImage({
            imageUrl,
            conversationId,
            messageId: api.requestId,
            retryId,
          })
        )
        .unwrap();

      const response = await sendMessageApi({
        to: [conversationId],
        options: {
          store_message_for_recipient: true,
          store_message_for_sender: true,
        },
        messages: [
          {
            message_type: ApiMessageType.IMAGE_MESSAGE,
            media: [
              { width, height, thumbnail_url: thumbnailUrl, download_url: url },
            ],
          },
        ],
      });

      emitMessageSentEvent(analyticsParams, {
        result: MessageStatusResult.SUCCESS,
      });

      return response;
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      emitMessageSentEvent(analyticsParams, {
        result: MessageStatusResult.FAILURE,
        reason: ChatMessageFailReason.API_ERROR,
      });

      return api.rejectWithValue(error);
    }
  }
);

interface DetectMessageLangResponse {
  language: string;
}

export const getMessageTranslation = createAsyncThunk<
  Partial<DetectMessageLangResponse & GetMessageTranslationResponse>,
  GetMessageTranslationParams,
  { rejectValue: string; state: RootState }
>("lwc/chat/translateMessage", async (params, api) => {
  try {
    const language = await detectMessageLang(params);
    if (language === params.locale) {
      return {
        language,
      };
    }

    const translationResponse = await getMessageTranslationApi(params);

    emitMessageTranslateEvent({
      chatId: params.conversationId,
      translateToLang: params.locale,
      screenName: getScreenNameByConversationId(params.conversationId),
      peerId: params.senderId,
      intValue: params.message.length,
      itemId: TranslateItemIds.OFFLINE_CHAT,
      itemType: TranslateItemType.SERVER,
      translateFromLang: language,
    });

    return {
      ...translationResponse,
      language,
    };
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const setIsTranslated = createAction<{
  conversationId: string;
  isTranslated: boolean;
  messageId: number;
}>("lwc/chat/setIsTranslated");

export const removeConversation = createAsyncThunk<
  DeleteMessageRequestsResponse,
  { conversationId: string },
  { rejectValue: string; state: RootState }
>("lwc/chat/removeConversation", async ({ conversationId }, api) => {
  try {
    return await deleteConversation(conversationId);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

interface FetchMosV2LineupChatParams {
  companionId: string;
}

export const getMosV2ActionForChat = createAsyncThunk<
  FetchMosV2ChatLineupResponse,
  FetchMosV2LineupChatParams,
  MosLineupRequestRejectError
>("lwc/mosChatV2/get", async ({ companionId }, { rejectWithValue }) => {
  try {
    const { items, lineupId } = await fetchMosV2ForChat({
      companionId,
    });

    return { items, lineupId };
  } catch (error) {
    if (error instanceof Error) {
      const message = error.message || MosError.SOMETHING_WENT_WRONG;

      return rejectWithValue({ message });
    }

    return rejectWithValue({ message: MosError.SOMETHING_WENT_WRONG });
  }
});

export const deleteChatMessage = createAsyncThunk<
  DeleteMessageRequestsResponse,
  { forAll: boolean; messages: StoredMessage[] },
  { rejectValue: string; state: RootState }
>("lwc/chat/deleteChatMessage", async ({ messages, forAll }, api) => {
  try {
    return await deleteMessage(messages, forAll);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;
    api.dispatch(
      addToast({
        type: ToastType.REGULAR,
        title: sharedMessages.somethingWrong,
      })
    );

    return api.rejectWithValue(error);
  }
});

export const editChatMessage = createAsyncThunk<
  DeleteMessageRequestsResponse,
  { message: StoredMessage },
  { rejectValue: string; state: RootState }
>("lwc/chat/editChatMessage", async ({ message }, api) => {
  try {
    return await editMessage(message);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;
    api.dispatch(
      addToast({
        type: ToastType.REGULAR,
        title: sharedMessages.somethingWrong,
      })
    );

    return api.rejectWithValue(error);
  }
});

export const pinConversation = createAsyncThunk<
  SetPinedChatResponse,
  { conversationId: string; pin?: boolean },
  { rejectValue: string; state: RootState }
>("lwc/chat/pinConversation", async ({ conversationId, pin }, api) => {
  try {
    return await setPinned({ conversationId, pin });
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;
    api.dispatch(
      addToast({
        type: ToastType.REGULAR,
        title: sharedMessages.somethingWrong,
      })
    );

    return api.rejectWithValue(error);
  }
});

export const muteConversation = createAsyncThunk<
  SetPinedChatResponse,
  { conversationId: string; mute?: boolean },
  { rejectValue: string; state: RootState }
>("lwc/chat/muteConversation", async ({ conversationId, mute }, api) => {
  try {
    return await setMuted({ conversationId, mute });
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;
    api.dispatch(
      addToast({
        type: ToastType.REGULAR,
        title: sharedMessages.somethingWrong,
      })
    );

    return api.rejectWithValue(error);
  }
});

export const setEditingMessageInProgress = createAction<
  Nullable<StoredMessage>
>("lwc/chat/setEditingMessageInProgress");

export const fetchChatEvents = createAsyncThunk<
  ChatEventsResponse,
  ChatEventsRequest,
  { rejectValue: string; state: RootState }
>("lwc/chat/fetchChatEvents", async (payload, api) => {
  try {
    return await getChatEventsRequest(payload);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const fetchMessagesById = createAsyncThunk<
  GetMessagesByIdResponse,
  GetMessagesByIdRequest,
  { rejectValue: string; state: RootState }
>("lwc/chat/fetchMessagesById", async (payload, api) => {
  try {
    return await getMessagesById(payload);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const sendVideoMessage = createAsyncThunk<
  SendMessageResponse,
  {
    analyticsParams: ChatMessageAnalyticsParams;
  } & BaseMessagePayload &
    VideoMessagePayload,
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/sendVideoMessage",
  async (
    { conversationId, mediaFile, retryId, analyticsParams, mediaData },
    api
  ) => {
    try {
      const converterResponse = await api
        .dispatch(
          uploadVideo({
            file: mediaFile,
            conversationId,
            messageId: api.requestId,
            retryId,
          })
        )
        .unwrap();

      const { thumbnailUrl, url, mediaId } = converterResponse;

      const videoInfo = await getVideoInfo(mediaFile);

      const mediaInfo = {
        download_url: url,
        thumbnail_url: thumbnailUrl,
        media_id: mediaId,
        ...mediaData,
        ...videoInfo,
      };

      const response = await sendMessageApi({
        to: [conversationId],
        options: {
          store_message_for_recipient: true,
          store_message_for_sender: true,
        },
        messages: [
          {
            message_type: ApiMessageType.VIDEO_MESSAGE,
            media: [mediaInfo],
          },
        ],
      });

      emitMessageSentEvent(analyticsParams, {
        result: MessageStatusResult.SUCCESS,
      });

      return response;
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      emitMessageSentEvent(analyticsParams, {
        result: MessageStatusResult.FAILURE,
        reason: ChatMessageFailReason.API_ERROR,
      });

      return api.rejectWithValue(error);
    }
  }
);

export const deleteChat = createAsyncThunk<
  DeleteChatResponse,
  DeleteChatPayload<DeleteChatRequest>,
  { rejectValue: string; state: RootState }
>("lwc/chat/deleteChat", async ({ payload }, api) => {
  try {
    return await deleteChatRequest(payload);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const leaveGroupChat = createAsyncThunk<
  LeaveGroupChatResponse,
  DeleteChatPayload<LeaveGroupChatRequest>,
  { rejectValue: string; state: RootState }
>("lwc/chat/leaveGroupChat", async ({ payload }, api) => {
  try {
    return await leaveGroupChatRequest(payload);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const getReactionsByChat = createAsyncThunk<
  GetReactionsByChatResponse,
  { conversationId: string } & GetReactionsByChatRequest,
  { rejectValue: string; state: RootState }
>("lwc/chat/getReactionsByChat", async (payload, api) => {
  try {
    return await getReactionsByChatRequest(payload);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const readReactionsToSelf = createAsyncThunk<
  ReadReactionsResponse,
  { conversationId: string },
  { rejectValue: string; state: RootState }
>("lwc/chat/readReactionsToSelf", async (payload, api) => {
  try {
    return await readReactionsToSelfRequest(payload);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const sendMessageReaction = createAsyncThunk<
  SendMessageReactionResponse,
  {
    analyticsParams: ChatReactionAnalyticsParams;
  } & SendMessageReactionRequest,
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/sendMessageReaction",
  async (
    { analyticsParams, message, reactionId, deleteReaction, messageSenderId },
    api
  ) => {
    try {
      const response = await sendMessageReactionRequest({
        message,
        messageSenderId,
        reactionId,
        deleteReaction,
      });

      if (!deleteReaction && analyticsParams) {
        emitReactionSentEvent(
          {
            ...analyticsParams,
            itemType: reactionId,
          },
          { result: ReactionStatusResult.SUCCESS }
        );
      }

      return response;
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      emitReactionSentEvent(analyticsParams, {
        result: ReactionStatusResult.FAILURE,
        reason: ChatMessageFailReason.API_ERROR,
      });

      return api.rejectWithValue(error);
    }
  }
);

export const updateMessageReaction = createAsyncThunk<
  SendMessageReactionResponse,
  {
    analyticsParams: ChatReactionAnalyticsParams;
  } & UpdateMessageReactionRequest,
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/updateMessageReaction",
  async (
    { oldReaction, newReaction, messageSenderId, message, analyticsParams },
    api
  ) => {
    try {
      if (oldReaction) {
        await sendMessageReactionRequest({
          reactionId: oldReaction,
          deleteReaction: true,
          messageSenderId,
          message,
        });
      }

      if (analyticsParams) {
        emitReactionSentEvent(
          {
            ...analyticsParams,
            itemType: newReaction,
          },
          { result: ReactionStatusResult.SUCCESS }
        );
      }

      return await sendMessageReactionRequest({
        reactionId: newReaction,
        deleteReaction: false,
        messageSenderId,
        message,
      });
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  }
);
