import React, { useCallback, useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { getMessageFlag } from "chat/analytics/emitMessageActionEvent";
import {
  CHAT_ANALYTICS_PARAMS_CHAT_SOURCE_ID,
  CHAT_ANALYTICS_PARAMS_CLICK_ACTION,
  CHAT_ANALYTICS_PARAMS_EMOJI_TARGET,
} from "chat/constants";
import { REACTION } from "chat/enums";
import { chatSelectors } from "chat/exports/state/selectors";
import { Typography } from "chat/imports/components";
import {
  CustomTypographyType,
  HeadlineTypographyType,
} from "chat/imports/constants";
import { RootState } from "chat/imports/state";
import { sendMessageReaction, updateMessageReaction } from "chat/state";
import { MessageIdentifier, TangoScreenState } from "chat/types";
import { disableEventBubbling } from "chat/utils/disableEventBubbling";
import isGroupChatId from "chat/utils/isGroupChatId";
import styles from "./MessageReactions.scss";

interface MessageReactionsProps {
  accountId?: string;
  isMyMessage: boolean;
  message: MessageIdentifier;
  messageSenderId: string | undefined;
}

const selector =
  (messageId: number, currentConversationId: null | string) =>
  (state: RootState) => ({
    allMessageReactions: chatSelectors.getMessageReactions(
      state,
      currentConversationId,
      messageId
    ),
    isMessageHasSingleReaction: chatSelectors.getIsMessageHasSingleReaction(
      state,
      currentConversationId,
      messageId
    ),
    storedMessage: chatSelectors.getMessageById(
      state,
      currentConversationId,
      messageId
    ),
    messageReaction: chatSelectors.getMyMessageReaction(
      state,
      currentConversationId,
      messageId
    ),
  });

export const MessageReactions = ({
  isMyMessage,
  messageSenderId,
  message,
  accountId = "",
}: MessageReactionsProps) => {
  const dispatch = useDispatch();
  const conversationId = useSelector(chatSelectors.getCurrentConversationId);
  const {
    allMessageReactions,
    isMessageHasSingleReaction,
    storedMessage,
    messageReaction,
  } = useSelector(selector(message.id, conversationId), shallowEqual);
  const isGroupChat = isGroupChatId(conversationId);
  const myReactionIndex = useMemo(
    () =>
      allMessageReactions?.messageReactions.findIndex(
        (reaction) => allMessageReactions?.myReactionId === reaction.reactionId
      ),
    [allMessageReactions?.messageReactions, allMessageReactions?.myReactionId]
  );

  const handleAnalyticsParams = useCallback(
    () => ({
      target: CHAT_ANALYTICS_PARAMS_EMOJI_TARGET,
      action: CHAT_ANALYTICS_PARAMS_CLICK_ACTION,
      chatId: conversationId ?? "",
      flags: [getMessageFlag(storedMessage!)],
      tangoScreen: isGroupChat
        ? TangoScreenState.CHAT_GROUP
        : TangoScreenState.CHAT_SINGLE,
      messageId: storedMessage?.id.id,
      sourceId: CHAT_ANALYTICS_PARAMS_CHAT_SOURCE_ID,
      recipientAccountId: accountId ?? "",
      itemType: messageReaction,
    }),
    [conversationId, storedMessage, accountId, messageReaction, isGroupChat]
  );

  const onReactionClickHandler =
    (reaction: REACTION) =>
    (
      e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
    ) => {
      e.stopPropagation();
      if (allMessageReactions?.myReactionId === reaction) {
        dispatch(
          sendMessageReaction({
            reactionId: reaction,
            deleteReaction: true,
            messageSenderId,
            message,
            analyticsParams: handleAnalyticsParams(),
          })
        );
      } else if (allMessageReactions.myReactionId) {
        dispatch(
          updateMessageReaction({
            oldReaction: allMessageReactions.myReactionId,
            newReaction: reaction,
            message,
            messageSenderId,
            analyticsParams: handleAnalyticsParams(),
          })
        );
      } else {
        dispatch(
          sendMessageReaction({
            reactionId: reaction,
            deleteReaction: false,
            messageSenderId,
            message,
            analyticsParams: handleAnalyticsParams(),
          })
        );
      }
    };

  return (
    <div
      className={classnames(styles.root, {
        [styles.myMessage]: isMyMessage,
        [styles.singleReaction]: isMessageHasSingleReaction,
        [styles.myReactionFirst]: myReactionIndex === 0,
        [styles.myReactionLast]:
          myReactionIndex === allMessageReactions?.messageReactions.length - 1,
      })}
    >
      {(allMessageReactions?.messageReactions ?? []).map(
        ({ reactionId, reactionCount }) => (
          <div
            className={classnames(styles.reaction, {
              [styles.myReaction]:
                allMessageReactions.myReactionId === reactionId,
            })}
            key={reactionId}
            onTouchStart={onReactionClickHandler(reactionId)}
            onClick={onReactionClickHandler(reactionId)}
            onMouseDown={disableEventBubbling}
          >
            <Typography type={HeadlineTypographyType.HEADLINE4}>
              {reactionId}
            </Typography>

            {!isMessageHasSingleReaction && reactionCount > 1 && (
              <Typography
                className={styles.reactionCount}
                type={CustomTypographyType.MINI}
              >
                <FormattedNumber
                  value={reactionCount}
                  notation={reactionCount >= 1000 ? "compact" : undefined}
                />
              </Typography>
            )}
          </div>
        )
      )}
    </div>
  );
};
