import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loadReferralUsersList } from "src/features/referralProgram/state/asyncActions";
import { getCategoriesApi } from "src/features/tangoAcademy/api/academyApi";
import {
  CategoriesResponse,
  Category,
} from "src/features/tangoAcademy/common/types";
import { RootState } from "src/features/tangoAcademy/imports/common";

const getCategories = createAsyncThunk<
  CategoriesResponse,
  void,
  { rejectValue: string; state: RootState }
>("lwc/tangoAcademy/getCategories", getCategoriesApi, {
  condition: (_, { getState }) => !getState().tangoAcademy.getCategories.data, // Cancel request if data already exists
});

type RequestState<T> =
  | { data: T; error: null; status: "succeeded" }
  | { data: null; error: null; status: "idle" }
  | { data: null; error: null; status: "loading" }
  | { data: null; error: string; status: "failed" };

export type TangoAcademyState = {
  getCategories: RequestState<Category[]>;
};

const initialState = {
  getCategories: {
    status: "idle",
    data: null,
    error: null,
  },
} as TangoAcademyState;

const slice = createSlice({
  name: "tangoAcademy",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state) => {
      state.getCategories = {
        status: "loading",
        error: null,
        data: null,
      };
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.getCategories = {
        status: "succeeded",
        data: action.payload.categories,
        error: null,
      };
    });
    builder.addCase(loadReferralUsersList.rejected, (state, action) => {
      state.getCategories = {
        status: "failed",
        error: action.error.message ?? "Something went wrong",
        data: null,
      };
    });
  },
});

export const tangoAcademyReducer = slice.reducer;
export { getCategories };
