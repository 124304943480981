import React, { FC, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "src/state/delegate";
import {
  connectionManagerSelectors,
  userSelectors,
  viewerSessionSelectors,
} from "src/state/selectors";
import { VoidCallback } from "src/types/common";
import { makePlayerAdapter } from "src/video/engine";

interface TangoPlayerProps {
  className: string;
  listeners: Record<string, VoidCallback>;
  muted: boolean;
  paused: boolean;
  url: string;
}

const selector = (state: RootState) => ({
  streamId: viewerSessionSelectors.getStreamId(state),
  userAccount: userSelectors.getMyAccountId(state),
  userId: connectionManagerSelectors.getUsername(state),
});

const applicationVersion = window?.twcVersion ?? "N/A";

const TangoPlayer: FC<TangoPlayerProps> = ({
  url,
  muted,
  paused,
  listeners,
  className,
}) => {
  const canvasRef = useRef(null);
  const [player] = useState(() => makePlayerAdapter(() => canvasRef.current));
  useEffect(
    () => () => {
      player.close();
    },
    [player]
  );

  const { streamId, userAccount, userId } = useSelector(selector, shallowEqual);

  const context = {
    applicationVersion,
    streamId,
    userAccount,
    userId,
  };

  player.onplay = listeners.play;
  player.onpause = listeners.pause;
  player.update(url, muted, paused, context);

  return (
    <canvas ref={canvasRef} className={className} width={720} height={1280} />
  );
};

export default TangoPlayer;
